<template>
  <div class="update-company-modal">
    <b-button variant="primary" size="sm" class="float-right" @click="$bvModal.show(`add-company-modal-${data}`)">
      <i class="ri-add-box-line "></i>
      Create New Company
    </b-button>

    <b-modal ref="updateCompanyModal" :id="`add-company-modal-${data}`" title="Add Company" hide-footer>
      <form @submit.prevent="addComapny">
        <div class="form-group">
          <label for="">Name</label>
          <input v-model="form.name" type="text" class="form-control" name="name"
            :class="{ 'is-invalid': submit && $v.form.name.$error }" placeholder="Name here" />
          <div v-if="submit && $v.form.name.$error" class="invalid-feedback">
            <span v-if="!$v.form.name.required">Name is required.</span>
          </div>
        </div>
        <div class="form-group">
          <label for="">Trade license number</label>
          <input v-model="form.trade_license_number" type="text" class="form-control" name="name" :class="{
            'is-invalid': submit && $v.form.trade_license_number.$error,
          }" placeholder="License number here" />
          <div v-if="submit && $v.form.trade_license_number.$error" class="invalid-feedback">
            <span v-if="!$v.form.trade_license_number.required">Trade license number is required.</span>
          </div>
        </div>
        <div class="form-group">
          <label for="">Trade license file</label>
          <b-form-file v-model="form.file" class="form-control" :state="!$v.form.file.$error"
            placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
          <div v-if="submit && $v.form.file.$error" class="invalid-feedback">
            <span v-if="!$v.form.file.required">Trade license file is required.</span>
          </div>
        </div>

        <div class="form-group mb-0 mt-2">
          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" size="sm">
              <span v-if="submitting">Submitting...</span>
              <span v-else> Submit </span>
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { advertiser } from "@/config/api/advertiser";
import { getFormData } from "@/utils/createForm";

export default {
  props: ["data"],
  data() {
    return {
      form: {
        file: [],
        name: "",

        trade_license_number: "",
      },
      submitting: false,
      submit: false,

    };
  },
  validations: {
    form: {
      file: {},
      name: { required },
      trade_license_number: {},
    },
  },
  mounted() {
  },
  methods: {
    addComapny() {
      this.submit = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const api = advertiser.createCompany;
          this.form.user_id = this.data;
         api.data = getFormData(this.form);
        this.submitting = true;
        this.generateAPI(api)
          .then((res) => {
            this.$bvToast.toast("Company added successfully!", {
              title: "Create",
              variant: "success",
              solid: true,
            });
            this.$v.$reset();
            this.form = {
              file: [],
              name: "",
              trade_license_number: "",
            };
            this.$emit("change", res.data.company);
            this.$bvModal.hide("add-company-modal");
          })
          .catch((err) => {
            this.$bvToast.toast(
              `Company is not added, ${err.response.data.error}!`,
              {
                title: "Create",
                variant: "danger",
                solid: true,
              }
            );
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
  },
};
</script>
