export const advertiser = {
  get: {
    url: "user/getAdvertisers",
    method: "GET",
    id: null,
    params: null,
  },
  getById: {
    url: "user/getAdvertiserById/",
    method: "GET",
    id: null,
    params: null,
  },
  loadAds: {
    url: "user/getCampaignsForCompany",
    method: "GET",
    id: null,
    params: null,
  },
  createCompany: {
    url: "admin/application/company/create",
    method: "POST",
    id: null,
    data: null,
  },
  editCompany: {
    url: "admin/application/company/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  editAdvertiser: {
    url: "admin/application/user/updateAdvertiser/",
    method: "PUT",
    id: null,
    data: null,
  },
  addAdvertiser: {
    url: "admin/user/createAdvertiser/",
    method: "POST",
    id: null,
    data: null,
  },
  loginById: {
    url: "admin/user/loginWithId/",
    method: "GET",
    id: null,
    params: null,
  },
};
