<template>
  <div class="edit-company-modal">
    <b-button
      variant="primary"
      size="sm"
      class="float-right"
      @click="$bvModal.show(`edit-company-modal-${data._id}`)"
    >
      <i class="ri-edit-box-line"></i>
    </b-button>

    <b-modal
      :id="`edit-company-modal-${data._id}`"
      title="Edit Company"
      hide-footer
    >
      <form @submit.prevent="editComapny">
        <div class="form-group">
          <label for="">Name</label>
          <input
            v-model="form.name"
            type="text"
            class="form-control"
            name="name"
            :class="{ 'is-invalid': submit && $v.form.name.$error }"
            placeholder="Name here"
          />
          <div v-if="submit && $v.form.name.$error" class="invalid-feedback">
            <span v-if="!$v.form.name.required">Name is required.</span>
          </div>
        </div>
        <div class="form-group">
          <label for="">Trade license number</label>
          <input
            v-model="form.trade_license_number"
            type="text"
            class="form-control"
            name="name"
            :class="{
              'is-invalid': submit && $v.form.trade_license_number.$error,
            }"
            placeholder="License number here"
          />
          <div
            v-if="submit && $v.form.trade_license_number.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.trade_license_number.required"
              >Trade license number is required.</span
            >
          </div>
        </div>

        <a :href="data.trade_license_doc" target="_blank">
          <div
            style="
              background: #e1e1e1;
              padding: 12px;
              border-radius: 4px;
              margin-bottom: 8px;
            "
          >
            <img :src="data.trade_license_doc" class="company-trade-licence" />
          </div>
        </a>

        <div class="form-group">
          <label for="">Trade license file</label>
          <b-form-file
            v-model="form.file"
            class="form-control"
            :state="!$v.form.file.$error"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
          <div v-if="submit && $v.form.file.$error" class="invalid-feedback">
            <span v-if="!$v.form.file.required"
              >Trade license file is required.</span
            >
          </div>
        </div>

        <div class="form-group mb-0 mt-2">
          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" size="sm">
              <span v-if="submitting">Submitting...</span>
              <span v-else> Submit </span>
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { advertiser } from "@/config/api/advertiser";
import { getFormData } from "@/utils/createForm";

export default {
  props: ["data"],
  data() {
    return {
      form: {
        file: [],
        name: this.data.name,
        trade_license_number: this.data.trade_license_number,
      },
      submitting: false,
      submit: false,
    };
  },
  validations: {
    form: {
      file: {},
      name: { required },
      trade_license_number: { required },
    },
  },
  methods: {
    editComapny() {
      this.submit = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const api = advertiser.editCompany;
        api.id = this.data._id;

        api.data = getFormData(this.form);
        this.submitting = true;
        this.generateAPI(api)
          .then((res) => {
            this.$bvToast.toast("Company edited successfully!", {
              title: "Edit",
              variant: "success",
              solid: true,
            });
            this.$v.$reset();
            this.$emit("edit", res.data.company);
            this.$bvModal.hide(`edit-company-modal-${this.data._id}`);
          })
          .catch((err) => {
            this.$bvToast.toast(
              `Company is not edited, ${err.response.data.error}!`,
              {
                title: "Edit",
                variant: "danger",
                solid: true,
              }
            );
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.company-trade-licence {
  width: 100%;
  max-width: 180px;
  margin: auto;
  display: block;
  border-radius: 4px;
  margin-bottom: 12px;
}
</style>
